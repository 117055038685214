<template>
  <div class="edit-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/video/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">视频管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改视频</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="left" label-width="100px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="editForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="视频标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="视频缩略图" prop="thumbnail">
          <upload :url="editForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="视频源" prop="type">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="1">系统</el-radio>
            <el-radio :label="2">外部链接</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="视频文件" prop="url" v-if="editForm.type === 1">
          <upload :url="editForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="视频外链地址" prop="url" v-if="editForm.type === 2">
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="editForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm('editRef')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      editForm: {
        mId: this.$route.params.mId,
        sort: 0,
        title: '',
        thumbnail: '',
        type: 1,
        url: '',
        is_auth: 2
      },
      editRules: {
        title: [
          { required: true, message: '请填写视频标题', trigger: 'blur' },
          { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传视频缩略图', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请上传视频或填写外站视频地址', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取被修改信息
    async getInfo () {
      const { data: res } = await this.$http.get('/shell-video/' + this.$route.params.id)
      if (res.status === 200) {
        this.editForm.sort = res.data.sort
        this.editForm.title = res.data.title
        this.editForm.thumbnail = res.data.thumbnail
        this.editForm.type = res.data.type
        this.editForm.url = res.data.url
        this.editForm.is_auth = Number(res.data.is_auth)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    // 修改表单
    submitForm () {
      this.$refs.editRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/shell-video/' + this.$route.params.id, this.editForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.editRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/video/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/video/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    }
  }
}
</script>

<style scoped>
.edit-form{
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>
